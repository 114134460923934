.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  margin:20px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0); */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#qrcodeToPrint, #mybarcode {margin-bottom:1rem;}
@media only screen and (max-width:767px) {
body .MuiButton-containedSizeLarge  {padding:0;}
body .MuiButton-containedSizeLarge svg {padding:20px !important; font-size:.4rem !important;}
.MuiButton-containedSizeSmall {padding:0; margin-bottom:1rem;}
.MuiButton-containedSizeSmall svg {padding:20px !important; font-size:.4rem !important;}
.MuiGrid-container .MuiGrid-root {margin-bottom:1.5rem;}
.MuiPaper-root {width:100% !important;}

.scannerDiv .MuiPaper-outlined {overflow:hidden;}
}
@media only screen and (max-width:600px) {
.container video {width:320px !important; height:320px !important;}
}