/* LoginForm.css */
.login-form {
	max-width: 300px;
	margin: 0 auto;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #f8f8f8;
	text-align: center;
  }
  
  .login-form h2 {
	margin: 0;
	padding-bottom: 20px;
  }
  
  .login-form label {
	display: block;
	text-align: left;
	font-weight: bold;
	margin-bottom: 5px;
  }
  
  .login-form input {
	width: 100%;
	padding: 8px;
	margin-bottom: 10px;
	border: 1px solid #ccc;
	border-radius: 3px;
  }
  
  .login-form button {
	width: 100%;
	padding: 10px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 3px;
	cursor: pointer;
  }
  