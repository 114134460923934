.barCodeIcons {
    // width: 100%;
    // height: 300px;
}
@media only screen and (min-width: 600px) {
    .scannerDiv{
        display: inline-block;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #000;
    }
    }
    @media only screen and (max-width: 400) {
        .scannerDiv{
           #interactive{
            width:320px
           }
           .MuiPaper-root{
            width:320px
           }
        }
        }