#barcodeToPrint img {
   
        display: none;
        width: 100%;
      

}
#barcode{
        width:95% !important;
        height: 225px;
      
}